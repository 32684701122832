import {
  AccountBalance,
  AccountBalanceWallet,
  AccountTree,
  AlignHorizontalLeft,
  Article,
  ArticleOutlined,
  AttachMoney,
  Calculate,
  CalculateOutlined,
  CollectionsBookmark,
  Construction,
  ContentPasteOutlined,
  Create,
  CurrencyExchange,
  Feed,
  FormatListBulleted,
  Groups,
  ImportExport,
  Input,
  Inventory,
  List,
  LocalShippingOutlined,
  Loop,
  Money,
  PriceChange,
  PriceCheck,
  Save,
  Schema,
  SsidChart,
  StackedLineChart,
  TableView,
  TrendingUp,
  ViewArray,
} from '@mui/icons-material'
import { ReactElement } from 'react'

interface SolutionsItemsProps {
  icon: ReactElement
  color: string
  label: string
  modalContent: string
  resources: {
    title: string
    icon: ReactElement
  }[]
}

export const SolutionsItems: Array<SolutionsItemsProps> = [
  {
    icon: <CalculateOutlined fontSize="large" />,
    color: 'rgb(195, 155, 211)',
    label: 'Vendas',
    modalContent:
      'Os documentos de Saída (Produtos e Serviços) serão gerados com base na Carteira de Pedidos e efetivados a partir de um Orçamento ou de um pedido direto. Através do recurso de prévia da Nota os usuários visualizam todos os detalhes da nota fiscal antes de sua confirmação.',
    resources: [
      {
        title: 'NF. Eletrônica Produtos/Serviços',
        icon: <Article fontSize="large" />,
      },
      {
        title: 'Orçamentos',
        icon: <ContentPasteOutlined fontSize="large" />,
      },
      {
        title: 'Carteira de Pedidos',
        icon: <AccountBalanceWallet fontSize="large" />,
      },
      {
        title: 'Controle Comissões',
        icon: <Groups fontSize="large" />,
      },
      {
        title: 'Contratos e Ordens de Serviço',
        icon: <CollectionsBookmark fontSize="large" />,
      },
      {
        title: 'Controle de Remessa/Retorno',
        icon: <Loop fontSize="large" />,
      },
    ],
  },
  {
    icon: <AttachMoney fontSize="large" />,
    color: 'rgb(69, 179, 157)',
    label: 'Compras',
    modalContent:
      'A partir das Requisições gerencia o processo de Entrada de mercadorias e serviços por meio das Ordens de Compras, confrontando-as com os documentos fiscais de entrada que, uma vez confirmados, atualizarão simultaneamente os saldos do Estoque e os compromissos junto ao Contas a Pagar. Já o comprador tem a sua disposição uma tela gerencial com todas as requisições em aberto, podendo definir os critérios para realizar as cotações da melhor forma possível (prazo, preço, urgência, etc).',
    resources: [
      {
        title: 'Requisições',
        icon: <FormatListBulleted fontSize="large" />,
      },
      {
        title: 'Cotações',
        icon: <Feed fontSize="large" />,
      },
      {
        title: 'Controle de Tolerância',
        icon: <TrendingUp fontSize="large" />,
      },
      {
        title: 'Recebimento das Mercadorias',
        icon: <LocalShippingOutlined fontSize="large" />,
      },
      {
        title: 'Integração Automática XMLs dos Fornecedores',
        icon: <TableView fontSize="large" />,
      },
      {
        title: 'Grupo/Subgrupo de produtos',
        icon: <AccountTree fontSize="large" />,
      },
    ],
  },
  {
    icon: <StackedLineChart fontSize="large" />,
    color: 'rgb(133, 118, 113)',
    label: 'Financeiro',
    modalContent:
      'Prático controle financeiro que envolve Contas a Pagar, Contas a Receber, Fluxo de Caixa, Contas-Correntes (Caixas e Bancos), Cobrança e Pagamento Escritural, e Módulo Gestor para extração de informações estratégicas. Integrado aos módulos de Faturamento (Saídas) e Compras (Entradas) com opção de gerenciar documentos previstos e realizar projeções financeiras a médio e longo prazo.',
    resources: [
      {
        title: 'Contas a Pagar/Receber em tela única',
        icon: <CurrencyExchange fontSize="large" />,
      },
      {
        title: 'Contas Correntes & Conciliação',
        icon: <AccountBalance fontSize="large" />,
      },
      {
        title: 'Controle de Cheques',
        icon: <Money fontSize="large" />,
      },
      {
        title: 'Cobrança/Pagamento Escritural',
        icon: <PriceCheck fontSize="large" />,
      },
      {
        title: 'Fluxo Caixa Analítico e Gerencial',
        icon: <SsidChart fontSize="large" />,
      },
      {
        title: 'Análises Horizontais',
        icon: <AlignHorizontalLeft fontSize="large" />,
      },
    ],
  },
  {
    icon: <LocalShippingOutlined fontSize="large" />,
    color: 'rgb(220, 118, 51)',
    label: 'Estoques',
    modalContent:
      'O controle físico de insumos, componentes, mercadorias, produtos acabados e transferências são gerenciados por este módulo de modo que os usuários possam visualizar de forma dinâmica toda a movimentação e os saldos por depósito. Controla também as informações gerenciais do tipo: preço médio, preço de custo, estoque mínimo, tributação especial, dias de ressuprimento, entre outras.',
    resources: [
      {
        title: 'Saldos por Depósito',
        icon: <AttachMoney fontSize="large" />,
      },
      {
        title: 'Movimentações em tela única',
        icon: <ImportExport fontSize="large" />,
      },
      {
        title: 'Transferências',
        icon: <Input fontSize="large" />,
      },
      {
        title: 'Inventário',
        icon: <Inventory fontSize="large" />,
      },
      {
        title: 'Pauta de Preços',
        icon: <PriceChange fontSize="large" />,
      },
      {
        title: 'Preparado para o Bloco K do SPED',
        icon: <ViewArray fontSize="large" />,
      },
    ],
  },
  {
    icon: <ContentPasteOutlined fontSize="large" />,
    color: 'rgb(112, 123, 124)',
    label: 'PCP',
    modalContent:
      'Através do detalhamento da ficha técnica dos Produtos Acabados, todos os componentes e insumos envolvidos no processo produtivo são cadastrados com a quantidade necessária para a produção de uma unidade. Todos os estágios que envolvem a fabricação do item também são discriminados junto a ficha técnica, para cálculo dos custos de fabricação.',
    resources: [
      {
        title: 'Estrutura de Produtos',
        icon: <Schema fontSize="large" />,
      },
      {
        title: 'Ordens de Produção',
        icon: <Construction fontSize="large" />,
      },
      {
        title: 'Projeção de Necessidades',
        icon: <List fontSize="large" />,
      },
      {
        title: 'Baixa do Estoque pela Ficha Técnica',
        icon: <Inventory fontSize="large" />,
      },
      {
        title: 'Apontamento de Horas',
        icon: <Create fontSize="large" />,
      },
      {
        title: 'Cálculo dos custos de produção',
        icon: <Calculate fontSize="large" />,
      },
    ],
  },
  {
    icon: <ArticleOutlined fontSize="large" />,
    color: 'rgb(66, 165, 245)',
    label: 'SPED',
    modalContent:
      'A geração dos arquivos do SPED é realizada de maneira prática neste módulo, baseado nas informações tratadas nos Módulos Fiscal e Contábil. São geradas as Escriturações Fiscais de ICMS-IPI e de PIS/COFINS. Já no módulo contábil é gerada a ECD (Escrituração Contábil Digital) e a ECF (Escrituração Contábil Fiscal).',
    resources: [
      {
        title: 'EFD ICMS/IPI com exportação (opcional) dos Itens das Nfe´s Emitidas',
        icon: <Save fontSize="large" />,
      },
      {
        title: 'Escrituração Contábil Digital Normal ou por Centro de Custo',
        icon: <Save fontSize="large" />,
      },
      {
        title: 'EFD PIS-COFINS com geração Detalhada ou Simplificada',
        icon: <Save fontSize="large" />,
      },
      {
        title: 'Integração com a Contabilidade',
        icon: <Save fontSize="large" />,
      },
    ],
  },
]
