import React, { ReactNode } from 'react'
import { ArticleOutlined, ContentPasteOutlined, Groups, Inventory } from '@mui/icons-material'

interface Data {
  title: string
  description: string
  icon?: ReactNode
}

export const data: Data[] = [
  {
    title: 'Software para Gestão Empresarial',
    description: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore',
    icon: <ContentPasteOutlined />,
  },
  {
    title: 'Facilidade de uso',
    description: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore',
    icon: <ArticleOutlined />,
  },
  {
    title: 'Processamento multi-empresa e multi usuário',
    description: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore',
    icon: <Groups />,
  },
  {
    title: 'Bancos de Dados Padrão SQL',
    description: 'Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore',
    icon: <Inventory />,
  },
]
