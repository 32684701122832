import React from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { FooterSocialLinks } from '../../components/footer';
import { Email, LocalPhone, LocationOn } from '@mui/icons-material';

const Footer = () => {
  return (
    <Box
      component="footer"
      id="contact"
      sx={{ backgroundColor: 'primary.main', py: { xs: 6, md: 4 }, color: 'primary.contrastText', width: '100%', minWidth: '78rem' }}
    >
      <Container sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', pt: 2 }}>
        <Grid item xs={12} md={5}>
          <Box
            sx={{
              width: 150,
              mb: { xs: 3, md: 0 },
              display: 'flex',
              flexDirection: 'column',
              gap: '0.8rem',
              minWidth: '15rem',
            }}
          >
            <img
              src="/images/logo-sisdm-branco.svg"
              alt="Sisdm Tech Logo"
              width={190}
              height={65}
              style={{ marginRight: '3rem', marginBottom: '1rem' }}
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          md={7}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '0.8rem',
            width: 370,
          }}
        >
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'start', gap: '0.3rem' }}>
            <LocationOn fontSize="small" />
            <Typography variant="subtitle1" sx={{ letterSpacing: 1, fontSize: '0.8rem', minWidth: '15rem' }}>
              Ágora Tech Park - Sala 217 - Rua Dona Francisca, n. 8300 - Distrito Industrial - Joinville - SC, 89219-600
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'start', gap: '0.3rem' }}>
            <LocalPhone fontSize="small" />
            <Typography variant="subtitle1" sx={{ letterSpacing: 1, fontSize: '0.8rem' }}>
              +55 (47) 99161-9319
            </Typography>
          </Box>
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'start', gap: '0.3rem', minWidth: '15rem' }}>
            <Email fontSize="small" />
            <Typography variant="subtitle1" sx={{ letterSpacing: 1, mb: 2, fontSize: '0.8rem' }}>
              sisdmtech@sisdmtech.com.br
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={12} md={7}>
          <FooterSocialLinks />
        </Grid>
      </Container>
    </Box>
  );
}

export default Footer;
