import React, { FC, useState, useEffect } from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import { StyledButton } from '../styled-button';
import { SolutionModal } from '../solution-modal';
import { AccountTree } from '@mui/icons-material';

interface SlideProps {
  parentWidth: number;
  parentHeight: number;
}

const FourthSlide: FC<SlideProps> = ({ parentHeight, parentWidth }: SlideProps) => {
  const [dimensions, setDimensions] = useState({ width: 600, height: 600 });
  const [resourcesModal, setResourcesModal] = useState({
    show: false,
    modalProps: {
      color: '#2F5BC5',
      headerItem: { title: 'Principais Recursos', icon: <AccountTree fontSize="large" /> },
      content:
        '<b>Gestão de Pedidos,</b> com opção de faturamento total/parcial, controle dos saldos a faturar, e replicação de pedidos.; <b>Módulo de Retorno para Industrializações.</b> Receba as notas do fornecedor e retorne os insumos automaticamente, controlando o saldo a retornar para cada nota. Fature os serviços da industrialização de forma agrupada ou individualizada cfe. os itens retornados - no módulo de industrialização, você define a melhor forma de faturar.; <b>Fluxo de Caixa Gerencial</b> com projeção de até 120 dias, com controle de inadimplência na mesma tela, e acompanhamento da evolução do fluxo via gráfico.;<b>Geração automática da necessidade de compra</b> de insumos/componentes para a produção, com base no que está pendente para produzir, ou com base nos pedidos de venda já digitados;<b>Apontamento de Horas</b> simplificado na produção, entregando o custo total de cada OP ou Projeto, em tela gerencial de fechamento.;<b>Plano de Contas Financeiro</b> para acompanhamento de relatórios e consultas gerenciais, de forma simples e eficaz.;',
    },
  });

  useEffect(() => {
    const handleResize = () => {
      if (parentHeight && parentWidth) {
        const width = parentWidth * 0.37;
        const height = parentHeight * 0.72;
        setDimensions({ width, height });
      } else {
        const width = window.innerWidth * 0.4;
        const height = window.innerHeight * 0.75;
        setDimensions({ width, height });
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [parentHeight, parentWidth]);

  return (
    <Container maxWidth="lg" sx={{ minWidth: '75rem' }}>
      {resourcesModal && resourcesModal.show && (
        <SolutionModal
          color={resourcesModal.modalProps.color}
          headerItem={resourcesModal.modalProps.headerItem}
          content={resourcesModal.modalProps.content}
          open={resourcesModal && resourcesModal.show}
          onClose={() => setResourcesModal((prevProps) => ({ ...prevProps, show: false }))}
        />
      )}
      <Grid container spacing={0} sx={{ pb: 10, pt: 8, xl: 15, px: 2 }}>
        <Grid item xs={4.5} md={5} sx={{ position: 'relative' }}>
          <Box
            sx={{ lineHeight: 0, display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: -3 }}
            height={600}
            width={600}
          >
            <img
              src="/images/fourth-slide-img.png"
              width={dimensions.width}
              height={dimensions.height}
              alt="laptop sisdm"
              style={{ maxWidth: '100%', height: 'auto' }}
            />
          </Box>
        </Grid>

        <Grid item xs={5} md={7}>
          <Box
            sx={{
              textAlign: 'left',
              height: '100%',
              display: 'flex',
              width: '80%',
              flexDirection: 'column',
              justifyContent: 'center',
              color: '#f1faff',
              marginLeft: 15,
            }}
          >
            <Box sx={{ mb: 3 }}>
              <Typography
                component="h2"
                sx={{
                  position: 'relative',
                  fontSize: { xs: 40, md: 47, xl: 57 },
                  letterSpacing: 1.5,
                  fontWeight: 'bold',
                  lineHeight: 1.3,
                }}
              >
                <Typography
                  component="mark"
                  sx={{
                    position: 'relative',
                    color: 'primary.contrastText',
                    fontSize: 'inherit',
                    fontWeight: 'inherit',
                    backgroundColor: 'unset',
                  }}
                >
                  Maximize seus Resultados com
                </Typography>
                <br />
                Esforço Mínimo
              </Typography>
            </Box>
            <Box sx={{ '& button': { mr: 2 } }}>
              <StyledButton
                color="primary"
                size="large"
                variant="contained"
                onClick={() => setResourcesModal((prevProps) => ({ ...prevProps, show: true }))}
              >
                Alguns dos principais recursos
              </StyledButton>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default FourthSlide;
