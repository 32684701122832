import React, { FC } from 'react'
import Box from '@mui/material/Box'
import { Link as ScrollLink } from 'react-scroll'
import { navigations } from './navigation.data'

const Navigation: FC = () => {
  return (
    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}>
      {navigations.map(({ path: destination, label }) => (
        <Box
          component={ScrollLink}
          key={destination}
          activeClass="current"
          to={destination}
          spy={true}
          smooth={true}
          duration={550}
          sx={{
            position: 'relative',
            color: 'text.disabled',
            cursor: 'pointer',
            fontWeight: 500,
            display: 'inline-flex',
            alignItems: 'center',
            justifyContent: 'center',
            px: { xs: 0, md: 3 },
            mb: { xs: 3, md: 0 },
            fontSize: { xs: '1.15rem', md: 'inherit' },
            transition: 'color 0.3s, box-shadow 0.6s',
            marginLeft: '0.2rem',

            ...(destination === '/' && {
              color: 'primary.main',
            }),

            '&.current > div': {
              display: 'block',
              transform: 'scaleX(1)',
            },

            '&:hover': {
              color: 'primary.main',
              boxShadow: '0 2px 0 0 currentColor',
              '& > div': {
                display: 'block',
                transform: 'scaleX(1)',
              },
            },
          }}
        >
          <Box />
          {label}
        </Box>
      ))}
    </Box>
  )
}

export default Navigation
