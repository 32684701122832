import type { Navigation } from '../../interfaces/navigation'

export const navigations: Navigation[] = [
  {
    label: 'Home',
    path: 'home',
  },
  {
    label: 'Soluções',
    path: 'solutions',
  },
  {
    label: 'Cases',
    path: 'cases',
  },
  {
    label: 'Sobre',
    path: 'about',
  },
  {
    label: 'Contato',
    path: 'contact',
  },
]
