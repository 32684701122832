import { FC, useState, useEffect } from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';
import { StyledButton } from '../styled-button';
import { Link as ScrollLink } from 'react-scroll';

interface SlideProps {
  parentWidth: number;
  parentHeight: number;
}

const FirstSlide: FC<SlideProps> = ({ parentHeight, parentWidth }: SlideProps) => {
  const [dimensions, setDimensions] = useState({ width: 600, height: 600 });

  useEffect(() => {
    const handleResize = () => {
      if (parentHeight && parentWidth) {
        const width = parentWidth * 0.4;
        const height = parentHeight * 0.8;
        setDimensions({ width, height });
      } else {
        const width = window.innerWidth * 0.4;
        const height = window.innerHeight * 0.75;
        setDimensions({ width, height });
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [parentHeight, parentWidth]);

  const handleChatClick = () => {
    window.open('https://tawk.to/chat/5d95168fdb28311764d6fbcf/default', '_blank');
  };

  return (
    <Container maxWidth="lg" sx={{ minWidth: '75rem' }}>
      <Grid
        container
        spacing={0}
        sx={{
          pb: { xs: 8, md: 10 },
          pt: { xs: 8, md: 8, xl: 15 },
          px: { md: 2, lg: 0 },
          minWidth: '75rem',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Grid item xs={6} md={7}>
          <Box
            sx={{
              textAlign: 'left',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              color: '#f1faff',
            }}
          >
            <Box sx={{ mb: 3 }}>
              <Typography
                component="h2"
                sx={{
                  position: 'relative',
                  fontSize: { xs: 38, md: 47, xl: 57 },
                  letterSpacing: 1.5,
                  fontWeight: 'bold',
                  lineHeight: 1.3,
                }}
              >
                Gestão Simplificada,
                <br />
                Empresa Competitiva
              </Typography>
            </Box>
            <Box sx={{ mb: 4, width: '75%' }}>
              <Typography sx={{ color: '#e0eaff', lineHeight: 1.6, fontSize: { xs: 14, md: 16, xl: 18 } }}>
                {
                  'O Sisdm é um ERP focado em otimizar processos entre os diversos setores da empresa, dando ganho de tempo e produtividade aos seus usuários. Com poucas pessoas operando o sistema, é possível gerenciar toda a cadeia produtiva e os processos administrativos de forma simples, eficaz e integrada.'
                }
              </Typography>
            </Box>
            <Box sx={{ '& button': { mr: 2 } }}>
              <StyledButton color="primary" size="large" variant="contained" onClick={handleChatClick}>
                Solicitar Orçamento
              </StyledButton>
              <ScrollLink to="about" spy={true} smooth={true} offset={0} duration={350}>
                <StyledButton color="primary" size="large" variant="outlined">
                  Saiba mais
                </StyledButton>
              </ScrollLink>
            </Box>
          </Box>
        </Grid>
        <Grid item xs={3} md={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Box
            sx={{ lineHeight: 0, display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            height={600}
            width={600}
          >
            <img
              src="/images/first-slide-img (1).png"
              width={dimensions.width}
              height={dimensions.height}
              alt="laptop sisdm"
            />
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default FirstSlide;
