import type { Case } from '../../interfaces/case'

export const data: Array<Case> = [
  {
    id: 1,
    cover: '/images/cases/ico_usiletro.png',
    content:
      'O sistema de gestão empresarial da SISDM Tech tem uma interface muito clara, como consequência, mesmo se for seu primeiro contato, sua utilização se torna muito fácil e simples. Também, é muito importante enfatizar o atendimento ao cliente, que além de estarem abertos a melhorias, sempre estão dispostos a auxiliar em qualquer dúvida ou problemas de forma ágil.',
    name: 'Giovana Gorniak da Silva',
    company: 'Usiletro Usinagem por Eletroerosão',
    url: 'www.usiletro.com.br/',
    local: 'Joinville/SC',
  },
  {
    id: 2,
    cover: '/images/cases/ico_alutec.png',
    content:
      'Venho aqui informar que a Alutec está muito satisfeita com o Sistema Sisdm em virtude das várias funcionalidades do produto. Após alguns anos de constante melhorias no uso internamente, hoje, os principais controles da empresa rodam dentro do sistema. Toda a equipe está muito familiarizada com o sistema onde, todos os cadastros de produtos, carteira de pedidos, preços, fluxo de caixa, controle de despesas, pedidos de compra, estoque de almoxarifado, financeiro, estão em uso diariamente. Outra funcionalidade que usamos diariamente é o acesso remoto ao sistema, onde temos um integrante da equipe que trabalha em home office. Isso nos ajudou imensamente durante a pandemia. O Sisdm hoje é a principal ferramenta de trabalho dentro da Alutec.',
    name: 'João Carlos Germani - Diretor',
    company: 'Alutec Fundição e Usinagem',
    url: 'www.alutecfundicao.com.br',
    local: 'Joinville/SC',
  },
  {
    id: 3,
    cover: '/images/cases/ico_rgc.svg',
    content:
      'Me adaptei facilmente ao modo de operação do sistema. Em pouco tempo já sabia utilizar quase todas as rotinas das quais hoje faço uso. Quanto a novos recursos e melhorias, todas as solicitações até hoje foram bem recebidas pela equipe SISDM Tech e retornadas com boas soluções. O que posso afirmar é que o SIS-dm nos atende bem e, quando necessário, sempre existe a possibilidade de sugestões ou solicitações de melhorias, ou até mesmo possibilidade de customizações.',
    name: 'Rodrigo Schlickmann - Gestor',
    company: 'RGC Laminados Ltda - ME',
    url: 'www.rgc.ind.br',
    local: 'Joinville/SC',
  },
]
