import { cloneElement, FC, useEffect, useRef, useState } from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { useInView } from 'react-intersection-observer'
import Slider, { Settings } from 'react-slick'
import { IconButton } from '@mui/material'
import { ArrowBackIosNew, ArrowForwardIos } from '@mui/icons-material'
import { SolutionsItems } from '../solutions/solutions.data'
import { SolutionCard } from '../solutions'
import { FirstHeaderSlide, FourthHeaderSlide, SecondHeaderSlide, ThirdHeaderSlide } from '../header-slider'

const HomeHeader: FC = () => {
  const parentRef = useRef<HTMLDivElement | null>(null)
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
    const updateDimensions = () => {
      if (parentRef.current) {
        setDimensions({
          width: parentRef.current.clientWidth,
          height: parentRef.current.clientHeight,
        })
      }
    }

    updateDimensions()
    window.addEventListener('resize', updateDimensions)

    return () => {
      window.removeEventListener('resize', updateDimensions)
    }
  }, [])

  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.1,
  })

  const sliderData = [
    <FirstHeaderSlide key="first" parentWidth={dimensions.width} parentHeight={dimensions.height} />,
    <SecondHeaderSlide key="second" parentWidth={dimensions.width} parentHeight={dimensions.height} />,
    <ThirdHeaderSlide key="third" parentWidth={dimensions.width} parentHeight={dimensions.height} />,
    <FourthHeaderSlide key="fourth" parentWidth={dimensions.width} parentHeight={dimensions.height} />,
  ]

  interface SliderArrowProps {
    onClick?: () => void
    type: 'next' | 'prev'
    className?: 'string'
  }

  const SliderArrow: FC<SliderArrowProps> = (props) => {
    const { onClick, type, className } = props
    return (
      <Box>
        <IconButton
          sx={{
            position: 'absolute',
            color: '#e3e3e3',
            '&:hover': { backgroundColor: 'primary.main', color: 'primary.contrastText' },
            bottom: '50% !important',
            left: type === 'prev' ? '10px !important' : 'unset !important',
            right: type === 'prev' ? 'unset !important' : '10px !important',
            transform: 'translateY(-50%)',
            zIndex: 10,
            boxShadow: 1,
            opacity: 0,
            visibility: 'hidden',
            transition: 'opacity 0.3s ease, visibility 0.6s ease',
          }}
          disableRipple
          color="inherit"
          onClick={onClick}
          className={className}
        >
          {type === 'next' ? <ArrowForwardIos sx={{ fontSize: 22 }} /> : <ArrowBackIosNew sx={{ fontSize: 22 }} />}
        </IconButton>
      </Box>
    )
  }

  const sliderConfig: Settings = {
    infinite: true,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 10000,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <SliderArrow type="prev" />,
    nextArrow: <SliderArrow type="next" />,
    dots: false,
    customPaging: () => (
      <Box sx={{ width: 30, backgroundColor: 'divider', display: 'inline-block', borderRadius: 4 }} />
    ),
  }

  return (
    <Box>
      <Box>
        <Box
          id="home"
          ref={parentRef}
          sx={{
            background: 'linear-gradient(40.5deg, #48d3fa -10%, #000046)',
            position: 'relative',
            borderRadius: '30px',
            boxShadow: '1px 1px 6px 4px #C3C4BF',
            overflow: 'hidden',
            '&:hover .slick-arrow': {
              opacity: 1,
              visibility: 'visible',
            },
            '.slick-arrow': {
              opacity: 0,
              visibility: 'hidden',
              transition: 'opacity 0.3s ease, visibility 0.3s ease',
            },
            maxHeight: '95vh',
            minHeight: '38rem',
            minWidth: '78rem',
          }}
        >
          <Slider {...sliderConfig}>
            {sliderData.map((item, index) => (
              <Box key={index}>
                {cloneElement(item, {
                  parentWidth: dimensions.width,
                  parentHeight: dimensions.height,
                })}
              </Box>
            ))}
          </Slider>
        </Box>
        <Box sx={{ position: 'absolute', top: 500 }} id="solutions" />
      </Box>
      <Box
        ref={ref}
        sx={{
          boxShadow: 1,
          py: 4,
          px: 7,
          borderRadius: 4,
          backgroundColor: 'background.paper',
          margin: '2rem auto',
          opacity: inView ? 1 : 0,
          transform: inView ? 'translateY(0)' : 'translateY(50px)',
          transition: 'opacity 0.6s ease-out, transform 0.6s ease-out',
          minWidth: '77rem',
          width: '100%',
        }}
        width={1300}
      >
        <Typography variant="h4" sx={{ mb: 2, textAlign: 'center', color: 'primary.main' }}>
          Soluções
        </Typography>
        <Typography variant="body1" sx={{ mb: 4, textAlign: 'fill', color: 'primary.main', lineHeight: 1.6 }}>
          Software de Gestão Empresarial que trata dos processos Administrativos da Empresa e controla o fluxo de
          informações de Clientes e Fornecedores desde a Entrada até a Saída dos produtos e serviços, incluindo as
          questões contábeis e tributárias relacionadas.
        </Typography>
        <Grid container spacing={2} sx={{ minWidth: '75rem' }}>
          {SolutionsItems.map((item) => (
            <Grid key={item.label} item md={2}>
              <SolutionCard item={item} />
            </Grid>
          ))}
        </Grid>
        <Box sx={{ width: '100%', p: 6, display: 'flex', justifyContent: 'center' }}>
          <Box
            component="img"
            src="/images/modulos2.png"
            alt="modulos"
            sx={{
              width: '100%',
              height: 'auto',
              maxWidth: '1200px',
              aspectRatio: '1200 / 250',
            }}
          />
        </Box>
      </Box>
    </Box>
  )
}

export default HomeHeader
