import React, { FC, useEffect, useState } from 'react';
import { Box, Container, Grid, Typography } from '@mui/material';

interface SlideProps {
  parentWidth: number;
  parentHeight: number;
}

const SecondSlide: FC<SlideProps> = ({ parentHeight, parentWidth }: SlideProps) => {
  const [dimensions, setDimensions] = useState({ width: 600, height: 600 });

  useEffect(() => {
    const handleResize = () => {
      if (parentHeight && parentWidth) {
        const width = parentWidth * 0.35;
        const height = parentHeight
        setDimensions({ width, height });
      } else {
        const width = window.innerWidth * 0.35;
        const height = window.innerHeight
        setDimensions({ width, height });
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [parentHeight, parentWidth]);

  return (
    <Container maxWidth="lg" sx={{ minWidth: '75rem' }}>
      <Grid container spacing={0} sx={{ flexDirection: 'row', alignItems: 'center' }}>
        <Grid item md={5} sx={{ position: 'relative' }}>
          <Box
            sx={{
              height: '100%',
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              marginLeft: -1,
              overflow: 'hidden',
            }}
          >
            <img
              src="/images/second-slide-img.png"
              width={dimensions.width}
              height={dimensions.height}
              alt="áreas de atuação"
            />
          </Box>
        </Grid>

        <Grid item md={7} sx={{ pb: 10, pt: 20 }}>
          <Box
            sx={{
              textAlign: 'left',
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              padding: '2rem',
              color: '#f1faff',
            }}
          >
            <Box sx={{ mb: 3 }}>
              <Typography
                component="h2"
                sx={{
                  position: 'relative',
                  fontSize: { xs: 40, md: 47, xl: 57 },
                  letterSpacing: 1.5,
                  fontWeight: 'bold',
                  lineHeight: 1.3,
                }}
              >
                Áreas de Atuação
              </Typography>
            </Box>
            <Box sx={{ mb: 4, width: '75%' }}>
              <Typography sx={{ color: '#e0eaff', lineHeight: 1.6, fontSize: 18 }} component="div">
                <ul style={{ paddingLeft: 20 }}>
                  <li>Ferramentaria / Usinagem</li>
                  <li>Indústria Produção Seriada</li>
                  <li>Indústria de Fabricação sob Encomenda (projetos industriais)</li>
                  <li>Metal Mecânico</li>
                  <li>Atacadistas e Distribuidoras com Emissão de NFe Modelo 55 </li>
                  <li>Outras Indústrias de Serviço</li>
                </ul>
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
};

export default SecondSlide;
