import React, { FC } from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import { data } from './about.data'
import { useInView } from 'react-intersection-observer'

const About: FC = () => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  })

  return (
    <Box
      sx={{
        py: 10,
        backgroundColor: 'background.paper',
        minWidth: '78rem'
      }}
    >
      <Box sx={{ position: 'absolute', top: 2200 }} id="about" />
      <Container ref={ref} sx={{ minwidth: '75rem' }}>
        <Grid container sx={{ minwidth: '75rem' }}>
          <Grid
            item
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              width: '100%',
              minwidth: '75rem',
            }}
          >
            <Typography
              component="h2"
              sx={{
                position: 'relative',
                fontSize: { xs: 40, md: 50 },
                ml: 4,
                mb: 3,
                lineHeight: 1,
                fontWeight: 'bold',
                color: 'primary.main',
                opacity: inView ? 1 : 0,
                transform: inView ? 'translateX(0)' : 'translateX(30px)',
                transition: 'opacity 0.6s ease-out, transform 0.6s ease-out',
              }}
            >
              Sobre
            </Typography>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-between',
                opacity: inView ? 1 : 0,
                transform: inView ? 'translateX(0)' : 'translateX(-30px)',
                transition: 'opacity 0.6s ease-out, transform 0.6s ease-out',
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  mb: 2,
                }}
              >
                <iframe
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/cmwlWqXLmSI?rel=0&amp;showinfo=0"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  style={{
                    opacity: inView ? 1 : 0,
                    transform: inView ? 'translateX(0)' : 'translateX(-30px)',
                    transition: 'opacity 0.6s ease-out, transform 0.6s ease-out',
                  }}
                />
              </Box>

              <Box>
                <Typography
                  sx={{
                    color: 'primary.main',
                    mb: 2,
                    ml: 4,
                    fontSize: '0.94rem',
                    textAlign: 'justify',
                    opacity: inView ? 1 : 0,
                    transform: inView ? 'translateX(0)' : 'translateX(30px)',
                    transition: 'opacity 0.6s ease-out, transform 0.6s ease-out',
                    minWidth: '38rem',
                  }}
                >
                  A SISDM Tech, empresa sediada em Joinville/SC, um dos mais importantes polos tecnológicos do Brasil,
                  atua desde 1990 no <strong>Desenvolvimento de Software para Gestão Empresarial</strong> destinado aos
                  mais variados segmentos de mercado que possuem características empreendedoras. Nossa solução é
                  integrada, porém permite a sua implantação por áreas de negócios. Dentre as suas características
                  destacam-se a{' '}
                  <strong>
                    facilidade de uso, o processamento multi-empresa e multi-usuário e o acesso gerenciado por meio de
                    senhas personalizadas
                  </strong>
                  conforme o nível de permissão de cada usuário. A confiabilidade das informações é assegurada pelo uso
                  dos principais <strong>Bancos de Dados padrão SQL</strong>, que oferecem aos Usuários excelente
                  interatividade nas consultas e rapidez no processamento. Além disso, nossos Sistemas são amplamente
                  parametrizáveis e foram concebidos de maneira a evoluir tecnicamente, acompanhando o crescimento de
                  nossos Clientes, permitindo adaptações especificas diante de qualquer necessidade.
                </Typography>
              </Box>
            </Box>

            <Grid container spacing={2} sx={{ ml: 2 }}>
              {data.map(({ title, icon }, index) => (
                <Grid key={String(index)} item md={6}>
                  <Box
                    sx={{
                      px: 2,
                      py: 1.5,
                      boxShadow: 1,
                      borderRadius: 4,
                      display: 'flex',
                      alignItems: 'center',
                      opacity: inView ? 1 : 0,
                      transform: inView ? 'translateX(0)' : 'translateX(-30px)',
                      transition: `opacity 0.6s ease-out ${index * 0.2 + 1.5}s, transform 0.6s ease-out ${
                        index * 0.2 + 1.5
                      }s`,
                    }}
                  >
                    <Box
                      sx={{
                        mr: 1,
                        backgroundColor: 'primary.main',
                        borderRadius: '50%',
                        height: 36,
                        width: 36,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        color: 'primary.contrastText',
                        '& svg': {
                          fontSize: 20,
                        },
                      }}
                    >
                      {icon}
                    </Box>
                    <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                      <Typography variant="h6" sx={{ fontSize: '1rem', color: 'text.primary' }}>
                        {title}
                      </Typography>
                      {/* <Typography sx={{ lineHeight: 1.3, color: 'text.secondary' }} variant="subtitle1">
                        {description}
                      </Typography> */}
                    </Box>
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default About
