import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { iconButtonClasses } from '@mui/material/IconButton';
import { Case } from '../../interfaces/case';

interface Props {
  item: Case;
}

const CaseCardItem: React.FC<Props> = ({ item }) => {
  return (
    <Box
      sx={{
        px: 1,
        py: 4,
        borderRadius: '16px',
      }}
    >
      <Box
        sx={{
          p: 2,
          backgroundColor: 'background.paper',
          borderRadius: '16px',
          width: 1500,
          transition: (theme) => theme.transitions.create(['box-shadow']),
          '&:hover': {
            boxShadow: 2,
            [`& .${iconButtonClasses.root}`]: {
              backgroundColor: 'primary.main',
              color: 'primary.contrastText',
            },
          },
          height: 500,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          paddingLeft: 10,
          gap: '2rem',
        }}
      >
        <Box
          sx={{
            lineHeight: 0,
            overflow: 'hidden',
            borderRadius: '16px',
          }}
        >
          <img
            src={item.cover}
            width={300}
            height={300}
            alt={'Case ' + item.id}
            style={{ borderRadius: '16px' }}
          />
        </Box>
        <Box sx={{ mt: 2 }}>
          <br />
          <Box sx={{ display: 'flex', alignItems: 'center', width: 550 }}>
            <Typography variant="body1" sx={{ mb: 4, textAlign: 'fill' }}>
              {item.content}
            </Typography>
          </Box>
          <Typography variant="body1" sx={{ mb: 4, textAlign: 'right' }}>
            <strong>
              {item.name}
              <br />
              {item.company}
            </strong>
            <br />
            {item.url}
            <br />
            {item.local}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default CaseCardItem;
