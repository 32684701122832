import React, { FC } from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Slider, { Settings } from 'react-slick'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
import { IconButton } from '@mui/material'
import IconArrowBack from '@mui/icons-material/ArrowBack'
import IconArrowForward from '@mui/icons-material/ArrowForward'

import { data } from './cases.data'
import { CaseCardItem } from '../../components/case'

interface SliderArrowProps {
  onClick?: () => void
  type: 'next' | 'prev'
  className?: 'string'
}

const SliderArrow: FC<SliderArrowProps> = (props) => {
  const { onClick, type, className } = props
  return (
    <Box>
      <IconButton
        sx={{
          backgroundColor: 'background.paper',
          color: 'primary.main',
          '&:hover': { backgroundColor: 'primary.main', color: 'primary.contrastText' },
          position: 'absolute',
          left: 'unset !important',
          bottom: -20,
          // bottom: { xs: '-70px !important', md: '-28px !important' },
          // left: 'unset !important',
          right: type === 'prev' ? '60px !important' : '0 !important',
          zIndex: 10,
          boxShadow: 1,
        }}
        disableRipple
        color="inherit"
        onClick={onClick}
        className={className}
      >
        {type === 'next' ? <IconArrowForward sx={{ fontSize: 22 }} /> : <IconArrowBack sx={{ fontSize: 22 }} />}
      </IconButton>
    </Box>
  )
}

const StyledDots = styled('ul')(({ theme }) => ({
  '&.slick-dots': {
    position: 'absolute',
    left: 0,
    bottom: -20,
    paddingLeft: theme.spacing(1),
    textAlign: 'left',
    '& li': {
      marginRight: theme.spacing(2),
      '&.slick-active>div': {
        backgroundColor: theme.palette.primary.main,
      },
    },
  },
}))

const HomeCases: FC = () => {
  const sliderConfig: Settings = {
    infinite: true,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 10000,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <SliderArrow type="prev" />,
    nextArrow: <SliderArrow type="next" />,
    dots: true,
    appendDots: (dots) => <StyledDots>{dots}</StyledDots>,
    customPaging: () => (
      <Box sx={{ height: 8, width: 30, backgroundColor: 'divider', display: 'inline-block', borderRadius: 4 }} />
    ),
  }

  return (
    <Box
      sx={{
        pt: {
          xs: 6,
          md: 8,
        },
        pb: 14,
        backgroundColor: 'background.default',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '100%',
        minWidth: '70rem'
      }}
    >
      <Box sx={{ position: 'absolute', top: 1350 }} id="cases" />
      <Typography variant="h1" sx={{ mt: { xs: 0, md: -5 }, fontSize: { xs: 30, md: 48 }, color: 'primary.main' }}>
        Cases
      </Typography>
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          <Grid item xs={12} md={5}>
            <Box
              sx={{
                height: '100%',
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <Slider {...sliderConfig}>
              {data.map((item) => (
                <CaseCardItem key={String(item.id)} item={item} />
              ))}
            </Slider>
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}

export default HomeCases
