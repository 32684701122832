import { Box, CssBaseline } from '@mui/material';
import { Footer } from './components/footer';
import { HomeAbout, HomeCases, HomeChat, HomeHeader } from './components/home';
import { MenuHeader } from './components/menu-header';
import { MUIProvider } from './providers';
import 'slick-carousel/slick/slick.css'
import './styles/globals.css'
import './styles/react-slick.css'

function App() {
  return (
    <Box component='main'>
      <MUIProvider>
        <CssBaseline />
        <MenuHeader />
        <HomeHeader />
        <HomeCases />
        <HomeAbout />
        <HomeChat />
        <Footer />
      </MUIProvider>
    </Box>
  );
}

export default App;
