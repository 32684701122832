import { ChatBubble, WhatsApp } from '@mui/icons-material'
import { Box } from '@mui/material'
import { FC } from 'react'

interface openChatProps {
  isChat: boolean
}

const Chat: FC = () => {
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const handleClick = ({ isChat }: openChatProps) => {
    isChat
      ? window.open('https://tawk.to/chat/5d95168fdb28311764d6fbcf/default', '_blank')
      : window.open('https://wa.me/5547991960430', '_blank')
  }

  return (
    <Box>
      <Box
        sx={{
          backgroundColor: 'rgba(35, 211, 102, 1.0)',
          borderRadius: 10000,
          position: 'fixed',
          bottom: 86,
          right: 15,
          padding: 2,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.5)',
          cursor: 'pointer',
          transition: 'transform 0.2s',
          '&:hover': {
            transform: 'scale(1.1)',
          },
        }}
        onClick={() => handleClick({ isChat: false })}
      >
        <WhatsApp fontSize="large" sx={{ color: 'white' }} />
      </Box>

      <Box
        sx={{
          backgroundColor: '#000046',
          borderRadius: 10000,
          position: 'fixed',
          bottom: 8,
          right: 15,
          padding: 2,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.5)',
          cursor: 'pointer',
          transition: 'transform 0.2s',
          '&:hover': {
            transform: 'scale(1.1)',
          },
        }}
        onClick={() => handleClick({ isChat: true })}
      >
        <ChatBubble fontSize="large" sx={{ color: 'white' }} />
      </Box>
    </Box>
  )
}

export default Chat
